import React   from 'react'
import Style   from './style.sass'

/**
 *  @version 2018/06/10
 */
export default class Loading extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    this.state = {
      loading: this.props.loading || false,
      message: '',
    }
  }

  /**
   *  レンダリング成功時
   *  @version 2018/06/10
   */
  componentDidMount() {
    
    // グローバルからの呼び出し
    window.startLoading = message => {

      this.start(message);
    };

    // グローバルからの呼び出し
    window.finishLoading = callback => {

      this.finish(callback);
    };
  }

  /**
   *  ローディング開始
   *  @version 2018/06/10
   */
  start = message => {

    this.setState({loading: true, message: message || 'Loading'});
  }

  /**
   *  ローディング終了
   *  @version 2018/06/10
   */
  finish = callback => {

    this.setState({loading: false, message: ''}, () => {
      if (callback) callback();
    });
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (this.state.loading ?
      <div className={Style.Loading} onMouseDown={e => e.stopPropagation()} onClick={e => e.stopPropagation()}>
        <div className={Style.Loading__ring}><div></div><div></div><div></div><div></div><div></div></div>
        <div className='u-mt-15'>{this.state.message}</div>
      </div>
      : null
    );
  }
}
